import React from "react";
import TextField from '@mui/material/TextField';

export default function TextFieldWithCharacterLimit(props) {
  let limit = props.maxLength ?? 0
  const [values, setValues] = React.useState({
    value: props.defaultValue ?? ""
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
      <TextField
        inputProps={{
          maxLength: props.lockOnLimit ? limit : undefined
        }}
        value={values.value}
        helperText={`${values.value.length}/${limit}` + (values.value.length > limit ? " Text too long." : "") }
        onChange={handleChange("value") }
        error={values.value.length > limit}
        {...props}
      />
  );
}
