import { putWithAuth } from "../Helpers/authHelper";
import { toast } from 'react-toastify';
import { API_URL } from "../config";

export async function updateSelectedArtist({ request, params }) {

    let data = await request.formData()

    return updateSelectedArtistDirect(data)
}

export async function updateSelectedArtistDirect(formData) {

    let selectedArtistId = Number(formData.get("SelectedArtistId"))

    if (selectedArtistId <= 0) {
        toast.error("Unknown artist ID.")
        return null
    }

    return putWithAuth(API_URL + "user", {
        SelectedArtistId: selectedArtistId,
    })

}

