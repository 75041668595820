import { Link, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import { Box, Button, Chip, Divider, Grid, TextField, Typography } from "@mui/material";
import AccordionList from "./AccordionList";
import { getSrcFromUserId } from "../config";
import { getAssignedChips, getTagChips } from "./Review";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export default function ReviewOverview() {
    const reviewGroups = Object.entries(useLoaderData());
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();

    let visible = ["LABEL"]
    if (searchParams.has("visible")) {
        visible = searchParams.getAll("visible")
    }

    let filter = ""
    if (searchParams.has("filter")) {
        filter = searchParams.get("filter")
    }


    const [visibleLabels, setVisibleLabels] = React.useState(visible);

    const handleVisibleLabels = (event, newVisibleLabels) => {
        setVisibleLabels(newVisibleLabels);
        setSearchParams({ visible: newVisibleLabels, filter: filterValue })
    };

    const [filterValue, setFilterValue] = React.useState(filter);

    const handleFilterChange = value => {
        setFilterValue(value.toLowerCase());
        setSearchParams({ filter: value, visible: visibleLabels })
    };

    if (!reviewGroups || reviewGroups.length === 0) {
        return <ErrorPage errorText={"No reviews found. Might be loading...."} />
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Divider sx={{ marginBottom: "10px" }}>Which types of Status should be shown?</Divider>
                    <ToggleButtonGroup
                        value={visibleLabels}
                        onChange={handleVisibleLabels}
                        aria-label="text formatting"
                        fullWidth
                        sx={(theme) => ({
                            marginBottom: "10px",
                            flexWrap: { xs: "wrap", sm: "nowrap" },
                            [theme.breakpoints.down("sm")]: {
                                border: "1px solid rgba(255, 255, 255, 0.12)",
                            },
                        })}
                    >
                        <ToggleButton className="allSelected" disableElevation value="ALL" aria-label="ALL">
                            <Typography>ALL</Typography>
                            {getVisibilityIcon("ALL", visibleLabels)}
                        </ToggleButton>
                        <ToggleButton className="labelSelected" disableElevation value="LABEL" aria-label="LABEL">
                            <Typography>LABEL </Typography>
                            {getVisibilityIcon("LABEL", visibleLabels)}
                        </ToggleButton>
                        <ToggleButton className="artistSelected" disableElevation value="ARTIST" aria-label="ARTIST">
                            <Typography>ARTIST</Typography>
                            {getVisibilityIcon("ARTIST", visibleLabels)}
                        </ToggleButton>
                        <ToggleButton className="externalSelected" disableElevation value="EXTERNAL" aria-label="EXTERNAL">
                            <Typography>EXTERNAL</Typography>
                            {getVisibilityIcon("EXTERNAL", visibleLabels)}
                        </ToggleButton>
                        <ToggleButton className="doneSelected" disableElevation value="DONE" aria-label="DONE">
                            <Typography>DONE</Typography>
                            {getVisibilityIcon("DONE", visibleLabels)}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Divider />
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: "10px", marginTop: "10px" }}>
                    <Link to={"/reviews/create"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        >
                            <Typography >Create new review</Typography>
                        </Button>
                    </Link>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: "10px", marginTop: "10px" }}>
                    <Divider>Filter</Divider>
                    <TextField
                        fullWidth
                        label="Filter (Artists / Title / Tag)"
                        placeholder="Filter (Artists / Title / Tag)"
                        defaultValue={filter}
                        onChange={(e) => handleFilterChange(e.target.value)}>
                    </TextField>
                </Grid>

                {reviewGroups.map((group, i) => {

                    if (!visibleLabels.includes(group[1].Type) && !visibleLabels.includes("ALL"))
                        return ""

                    let reviews = group[1].Reviews.filter(review => 
                        review.ArtistName.toLowerCase().includes(filterValue)
                        || review.TrackName.toLowerCase().includes(filterValue)
                        || review.Tags.some(tag => tag.toLowerCase().includes(filterValue))
                    )

                    if (reviews.length === 0) {
                        return ""
                    }

                    return (
                        <Grid key={group[0]} item xs={12}>
                            <Box textAlign={'center'}>
                                <AccordionList
                                    data={reviews}
                                    getPrimaryText={getPrimaryText}
                                    header={getHeader(group)}
                                    secondaryHeader={getSecondaryHeader(reviews)}
                                    getAvatarSrc={getAvatarSrc}
                                    onClick={({ Id }) => navigate("/reviews/" + Id)}
                                    defaultExpanded={i === 0}
                                    className={group[1].Type.toLowerCase() + " fade-in"}
                                />
                            </Box>
                            <br />

                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
}

const getVisibilityIcon = (type, visibleTypes) => {
    return visibleTypes.includes(type)
        ? <VisibilityIcon></VisibilityIcon>
        : <VisibilityOffIcon></VisibilityOffIcon>
}

const getAvatarSrc = (interaction) => {
    return getSrcFromUserId(interaction.UserId)
}

const getSecondaryHeader = (group) => {

    return "(" + group.length + " reviews)"
}

const getHeader = (group) => {
    return (<>
        {group[0]}
        {getAssignedChips(
            {
                NeedsHv: group[1].Reviews.some(review => review.NeedsHv),
                NeedsPint: group[1].Reviews.some(review => review.NeedsPint)
            })
        }
    </>)
}

const getPrimaryText = (review) => {
    return (
        <>
            {review.ArtistName + " - " + review.TrackName}
            <Chip label={review.ReviewStatus.Name} style={{ color: review.ReviewStatus.Color, marginLeft: "10px" }} />
            {getAssignedChips(review)}

            {
                getTagChips(review, false)
            }
        </>)

}