import { User } from "oidc-client-ts";

export function getToken(){
    const oidcStorage = sessionStorage.getItem(`oidc.user:https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_CNzkmk0y1:1r1glfk1e8ulq2po5tbf10440o`)
    if (!oidcStorage) {
        return null;
    }

    var access_token = User.fromStorageString(oidcStorage)?.access_token
    return access_token;
}

export async function fetchWithAuth(url, options){
    var token = getToken();

    const response = await fetch(url, { 
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "X-Version": "2"
        },
        ...options
    })

    if (!response.ok || response.headers.get("Content-Length") === "0") {
        return {
            success: response.ok,
            data: []
        }
    }

    return {
        success: response.ok,
        data: await response.json()
    };
}

export async function postWithAuth(url, data){
    const options = {
        method: "POST",
        body: JSON.stringify(data)
    }

    return fetchWithAuth(url, options)
}

export async function deleteWithAuth(url, data){
    const options = {
        method: "DELETE",
        body: JSON.stringify(data)
    }

    return fetchWithAuth(url, options)
}

export async function putWithAuth(url, data){
    const options = {
        method: "PUT",
        body: JSON.stringify(data)
    }

    return fetchWithAuth(url, options)
}