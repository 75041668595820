import { API_URL } from "../config";
import { fetchWithAuth } from "../Helpers/authHelper";

export async function loadAllDashboardData({ params }) {

  return {
    artists: (await fetchWithAuth(API_URL + "artist")).data,
    user: (await fetchWithAuth(API_URL + "current-user")).data
  }
}
