import { useLoaderData, useRouteLoaderData, useSubmit } from "react-router-dom";
import React from 'react';
import TypoGraphy from '@mui/material/Typography'
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import TextFieldWithCharacterLimit from "./TextFieldWithCharacterLimit";
import ButtonMenu from "./ButtonMenu";

export default function CreateReleaseTask() {
    const users = useLoaderData();
    const user = useRouteLoaderData("dashboard").user

    const submit = useSubmit();

    const [values, setValues] = React.useState({
        userId: user.Id
    });

    const handleChange = (name, item) => {
        setValues({ ...values, [name]: item.Id });
    };    

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <TypoGraphy variant="h4">
                            Create new release task
                        </TypoGraphy>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TextFieldWithCharacterLimit
                        id="Text"
                        label="Text:"
                        placeholder="Text:"
                        name="Text"
                        fullWidth
                        multiline
                        maxLength={255}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        type={"number"}
                        id="ReminderOffsetInDays"
                        label="Reminder offset days (negative for before release date):"
                        placeholder="Reminder offset days (negative for before release date):"
                        name="ReminderOffsetInDays"
                    />
                </Grid>

                <Grid item xs={6}>
                    <ButtonMenu
                        id="button"
                        name="button"
                        header={users.find(u => u.Id === values.userId).Name}
                        buttonId="UserId"
                        options={users}
                        onItemClick={(item) => handleChange("userId", item)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        onClick={() => handleSubmit(submit, values.userId)}
                    >
                        <Typography >Save</Typography><SaveIcon></SaveIcon>
                    </Button>
                </Grid>

            </Grid>
        </>
    );
}

const handleSubmit = (submit, userId) => {
    let text = document.getElementById("Text").value
    let offset = document.getElementById("ReminderOffsetInDays").value

    let formData = new FormData();
    formData.append("Text", text);
    formData.append("ReminderOffsetInDays", offset);
    formData.append("UserId", userId);
    submit(formData, { method: "post" });
}

