import { useRouteError } from "react-router-dom";
import React from 'react';
import { withAuthenticationRequired } from "react-oidc-context";

export default withAuthenticationRequired(function ErrorPage(props) {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message || props.errorText}</i>
      </p>
    </div>
  );
}, {signinRedirectArgs: {url_state: window.location.pathname}})