export const API_URL = "https://api.tlwn.de/"
export const HV_PIC = "https://cdn.tlwn.de/hv.jpg"
export const PINT_PIC = "https://cdn.tlwn.de/pint.jpg"

export function getSrcFromUserId(userId) {
    switch (userId) {
        case 0:
            return HV_PIC

        case 1:
            return PINT_PIC
        default:
            break;
    }
}