import { Form, Link } from "react-router-dom";
import React from 'react';
import TypoGraphy from '@mui/material/Typography'
import { Box, Button, Grid, TextField, Typography } from "@mui/material";


export default function CreateReview() {


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <TypoGraphy variant="h3">
                            Create New Review
                        </TypoGraphy>
                        <br />
                        <Form method="post">

                            <TextField
                                id="ArtistName"
                                label="Artist Name"
                                placeholder="Artist Name"
                                name="ArtistName"
                                fullWidth
                                required
                                sx={{ marginBottom: "35px" }}
                            />

                            <TextField
                                id="TrackName"
                                label="Track Name"
                                placeholder="Track Name"
                                name="TrackName"
                                fullWidth
                                required

                                sx={{ marginBottom: "35px" }}
                            />

                            <Grid container>

                            <Grid item xs={4} >
                                    <Link to={"/reviews"}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                                            color="error"
                                        >
                                            <Typography >Cancel</Typography>
                                        </Button>
                                    </Link>
                                </Grid>

                                <Grid item xs={4}> <></></Grid>

                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                                        color="success"
                                        type="submit"
                                    >
                                        <Typography >Add</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>

                        <br />
                        <br />
                    </Box>
                </Grid>

            </Grid>
        </>
    );
}