import { Link, useLoaderData, useParams, useSubmit } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

export default function Artist() {
    const artist = useLoaderData()
    const { ArtistName } = useParams()
    const submit = useSubmit()

    if (!artist || artist.length === 0) {
        return <ErrorPage errorText={"No artist with name '" + ArtistName + "' found"} />
    }

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <Typography variant="h4">
                            {artist.Name}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Link to={"/artists/"+ artist.Name + "/tracks"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        >
                            <Typography>View Tracks</Typography>
                        </Button>
                    </Link>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        defaultValue={artist.Name}
                        fullWidth
                        id="artistName"
                        label="Artist Name:"
                        placeholder="Artist Name:"
                        name="artistName"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        defaultValue={artist.Firstname}
                        fullWidth
                        id="firstName"
                        label="First Name:"
                        placeholder="First Name:"
                        name="firstName"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        defaultValue={artist.Lastname}
                        fullWidth
                        id="lastName"
                        label="Last Name:"
                        placeholder="Last Name:"
                        name="lastName"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        defaultValue={artist.Country}
                        fullWidth
                        id="country"
                        label="Country:"
                        placeholder="Country:"
                        name="country"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        defaultValue={artist.City}
                        fullWidth
                        id="city"
                        label="Address:"
                        placeholder="Address:"
                        name="city"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        defaultValue={artist.Paymentinfo}
                        fullWidth
                        id="paymentinfo"
                        label="Paymentinfo:"
                        placeholder="Paymentinfo:"
                        name="paymentinfo"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        defaultValue={artist.ChannelId}
                        fullWidth
                        id="channelId"
                        label="Discord Channel Id:"
                        placeholder="Discord Channel Id:"
                        name="channelId"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        onClick={() => handleSubmit(submit, artist.Id)}
                    >
                        <Typography >Save</Typography><SaveIcon></SaveIcon>
                    </Button> 
                </Grid>

            </Grid>
        </>
    );
}

const handleSubmit = (submit, artistId) => {
    let name = document.getElementById("artistName").value
    let firstName = document.getElementById("firstName").value
    let lastName = document.getElementById("lastName").value
    let country = document.getElementById("country").value
    let city = document.getElementById("city").value
    let paymentinfo = document.getElementById("paymentinfo").value
    let channelId = document.getElementById("channelId").value

    let formData = new FormData();
    formData.append("Name", name);
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);
    formData.append("Country", country);
    formData.append("City", city);
    formData.append("Paymentinfo", paymentinfo);
    formData.append("ChannelId", channelId);
    formData.append("Id", artistId);
    submit(formData, { method: "post" });
}
