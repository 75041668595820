import { API_URL } from "../config";
import { fetchWithAuth } from "../Helpers/authHelper";

export async function loadReleaseTaskData({ params }) {
  return [
    (await fetchWithAuth(API_URL + "task/" + params.Id)).data,
    (await fetchWithAuth(API_URL + "user")).data,
  ]
}

export async function loadAllReleaseTasks() {
  return (await fetchWithAuth(API_URL + "task")).data
}

export async function loadAllUsers() {
  return (await fetchWithAuth(API_URL + "user")).data
}
