import { useLoaderData, useParams, useSubmit } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import { Box, Divider, Grid, Typography } from "@mui/material";
import AccordionList from "./AccordionList";
import { getAssignedChips } from "./Review";
import { getSrcFromUserId } from "../config";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';


const getUserName = (users, userId) => {
    return users.find(u => u.Id === userId)?.Name
}

const getAvatarSrc = (releaseTask) => {
    return getSrcFromUserId(releaseTask.UserId)
}

const getHeader = (group) => {

    let userId = group[1][0].UserId
    return (<>
        {group[0]}
        {getAssignedChips(
            {
                NeedsHv: userId === 0,
                NeedsPint: userId === 1
            })
        }
    </>)
}

const getSecondaryHeader = (group) => {
    const doneCount = group.filter(t => t.IsDone).length
    return "(" + doneCount + " / " + group.length + " done) " + (doneCount === group.length ? "✅" : "❌")
}

const getPrimaryText = (releaseTask) => {
    return (
        <>
            {releaseTask.Text}
        </>)

}

const handleTaskClick = (releaseTask, trackId, submit) => {
    let formData = new FormData();
    formData.append("TrackId", trackId);
    formData.append("ReleaseTaskId", releaseTask.Id);
    formData.append("Action", releaseTask.IsDone ? "Delete" : "Post");
    submit(formData, { method: "post" });
}

export default function Track() {
    const [track, users, currentUser] = useLoaderData()
    const { ArtistName, TrackName } = useParams()
    const submit = useSubmit()

    const taskGroups = Object.entries(Object.groupBy(track.Tasks, task => getUserName(users, task.UserId)));


    if (!track || track.length === 0) {
        return <ErrorPage errorText={"No track with name '" + TrackName + "' for artist: '" + ArtistName + "' found"} />
    }

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <Typography variant="h4">
                            {track.Name}
                        </Typography>
                    </Box>
                    <br></br>
                    <Divider></Divider>
                    <br></br>
                </Grid>

                {taskGroups.map((group, i) => {
                    return (
                        <Grid key={group[0]} item xs={12}>
                            <Box textAlign={'center'}>
                                <AccordionList
                                    data={group[1]}
                                    getPrimaryText={getPrimaryText}
                                    header={getHeader(group)}
                                    secondaryHeader={getSecondaryHeader(group[1])}
                                    getAvatarSrc={getAvatarSrc}
                                    defaultExpanded={group[0] === currentUser.Name}
                                    getIconAvatarSrc={(releaseTask) => releaseTask.IsDone ? <DoneIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />}
                                    getSecondaryText={(releaseTask) => releaseTask.IsDone ? "click to mark as NOT done." : "click to mark as done."}
                                    onClick={(releaseTask) => handleTaskClick(releaseTask, track.Id, submit)}
                                />
                            </Box>
                            <br />

                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
}