import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function ArtistOverview() {
    const artists = useRouteLoaderData("dashboard").artists
    const navigate = useNavigate();

    if (!artists || artists.length === 0) {
        return <ErrorPage errorText={"No artists found. Might be loading...."} />
    }

    const columns = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'PaidToArtist',
            headerName: 'Paid to Artist (€)',
            flex: 0.33,
        },
        {
            field: 'PaidToArtistMissing',
            headerName: 'Open to Artist (€)',
            flex: 0.33,
        },
        {
            field: 'LabelAmount',
            headerName: 'Label Amount (€)',
            flex: 0.33,
        }
    ];

    return (
        <>
            <Grid container>

                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <Link to={"/artists/create"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        >
                            <Typography >Create new Artist</Typography>
                        </Button>
                    </Link>
                    <Box sx={{ height: 700, width: '100%' }} textAlign={'center'}>
                        <DataGrid
                            rows={artists}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            getRowId={(row) => row.Id}
                            autoPageSize
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: { disableToolbarButton: true },
                                    csvOptions: { disableToolbarButton: true },
                                },
                            }}
                                    onRowClick={(r) => navigate("/artists/" + r.row.Name)}
                        />
                    </Box>
                </Grid>



            </Grid>
        </>
    );
}
