import { Link, useLoaderData, useNavigate } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import { Box, Button, Grid, Typography } from "@mui/material";
import AccordionList from "./AccordionList";
import { getSrcFromUserId } from "../config";
import { getAssignedChips } from "./Review";


export default function ReleaseTaskOverview() {
    const taskGroups = Object.entries(Object.groupBy(useLoaderData(), task => task.Username));
    const navigate = useNavigate();

    if (!taskGroups || taskGroups.length === 0) {
        return <ErrorPage errorText={"No tasks found. Might be loading...."} />
    }

    return (
        <>
            <Grid container>

                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <Link to={"/tasks/create"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        >
                            <Typography >Create new release task</Typography>
                        </Button>
                    </Link>
                </Grid>

                {taskGroups.map((group, i) => {
                    return (
                        <Grid key={group[0]} item xs={12}>
                            <Box textAlign={'center'}>
                                <AccordionList
                                    data={group[1]}
                                    getPrimaryText={getPrimaryText}
                                    header={getHeader(group)}
                                    secondaryHeader={getSecondaryHeader(group[1])}
                                    getAvatarSrc={getAvatarSrc}
                                    onClick={({ Id }) => navigate("/tasks/" + Id)}
                                    getSecondaryText={formatOffset}
                                />
                            </Box>
                            <br />

                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
}

const getAvatarSrc = (releaseTask) => {
    return getSrcFromUserId(releaseTask.UserId)
}

const getHeader = (group) => {

    let userId = group[1][0].UserId
    return (<>
        {group[0]}
        {getAssignedChips(
            {
                NeedsHv: userId === 0,
                NeedsPint: userId === 1
            })
        }
    </>)
}

const getSecondaryHeader = (group) => {

    return "(" + group.length + " tasks)"
}

const getPrimaryText = (releaseTask) => {
    return (
        <>
            {releaseTask.Text}
        </>)

}

export const formatOffset = (releaseTask) => {
    let offset = releaseTask.ReminderOffsetInDays
    if (offset === 0) {
        return "At release day"
    }

    let suffix = offset > 0
        ? "after"
        : "before"

    return Math.abs(offset) + " days " + suffix + " release"
}