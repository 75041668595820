import { Link, useLoaderData, useParams, useRouteLoaderData, useSubmit } from "react-router-dom";
import React from 'react';
import { Box, Button, Divider, Grid, Slider, Stack, TextField, Typography } from "@mui/material";
import ButtonMenu from "./ButtonMenu";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import 'dayjs/locale/de';
import ArtistSplit from "./ArtistSplit";
import { toast } from "react-toastify";


export default function CreateTrack() {
    const artists = useRouteLoaderData("dashboard").artists
    const releaseTags = useLoaderData()
    const { ArtistName } = useParams()
    const submit = useSubmit()

    let artist = artists.find(a => a.Name.toLowerCase() === ArtistName.toLowerCase())
    let defaultArtistData = {}
    defaultArtistData[artist.Id] = 100

    const [values, setValues] = React.useState({
        releaseTagId: 0,
        artistData: defaultArtistData,
        split: 70
    });

    const handleChange = (name, item) => {
        setValues({ ...values, [name]: item });
    };

    const handleArtistDataChange = (id, split, oldId) => {
        var oldArtistData = values.artistData
        oldArtistData[id] = split

        if (id !== oldId) {
            delete oldArtistData[oldId]
        }

        setValues({ ...values, artistData: oldArtistData });
    };

    const removeLastArtist = () => {
        var oldArtistData = values.artistData

        if (Object.keys(oldArtistData).length < 2) {
            return;
        }
       
        var lastArtistId = Object.keys(oldArtistData).at(-1)

        delete oldArtistData[lastArtistId]

        setValues({ ...values, artistData: oldArtistData });
    };

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <Typography variant="h4">
                            Create Track for: {ArtistName}
                        </Typography>
                    </Box>
                    <Divider>Artists</Divider>
                </Grid>

                <Grid item xs={12}>
                    {Object.entries(values.artistData).map((data, i) => {
                        return (<ArtistSplit
                            data={artists}
                            handleChange={(item, split, oldId) => handleArtistDataChange(item?.Id, split, oldId)}
                            defaultValue={artists.find(a => a.Id + "" === data[0])}
                            disabled={i === 0}
                            index={i + 1}
                        />)
                    })}
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        onClick={removeLastArtist}
                    >
                        <Typography>Remove Artist</Typography>
                    </Button>

                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        onClick={() => handleArtistDataChange("", 100)}
                    >
                        <Typography>Add Artist</Typography>
                    </Button>

                </Grid>

                <Grid item xs={12}>
                    <Divider>Track data</Divider>
                </Grid>

                <Grid item xs={8}>

                    <Typography id="input-slider" gutterBottom>
                        Artist portion of the split:
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ mb: 1, marginTop: "19px" }} alignItems="center">
                        <Typography>0%</Typography>
                        <Slider
                            aria-label="Volume"
                            value={values.split}
                            step={10}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="on"
                            id="Split"
                            onChange={(e, item) => handleChange("split", item)}
                        />
                        <Typography>100%</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Typography id="input-slider" gutterBottom>
                        Release Tag:
                    </Typography>
                    <ButtonMenu
                        id="button"
                        name="button"
                        header={releaseTags.find(u => u.Id === values.releaseTagId).Name}
                        buttonId="releaseTags"
                        options={releaseTags}
                        fullWidth
                        onItemClick={(item) => handleChange("releaseTagId", item.Id)}
                    />
                </Grid>

                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        id="TrackName"
                        label="Track Name:"
                        placeholder="Track Name:"
                        name="TrackName"
                    />
                </Grid>

                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <MobileDatePicker defaultValue={dayjs()}
                            label="Release Date"
                            slotProps={{
                                textField: {
                                    id: "ReleaseDate",
                                    fullWidth: true
                                },
                            }} />
                    </LocalizationProvider>

                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ marginTop: "8px", marginBottom: "8px" }}></Divider>
                </Grid>

                <Grid item xs={4} >
                    <Link to={"/artists"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                            color="error"
                        >
                            <Typography>Cancel</Typography>
                        </Button>
                    </Link>
                </Grid>

                <Grid item xs={4}> <></></Grid>

                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        color="success"
                        onClick={() => handleSubmit(submit, values)}
                    >
                        <Typography>Add</Typography>
                    </Button>
                </Grid>
            </Grid>

        </>
    );
}

const handleSubmit = (submit, values) => {

    let artistDataSplits = Object.entries(values.artistData);
    let splits = 0

    let trackArtists = []

    artistDataSplits.forEach(artistDataSplit => {
        splits += artistDataSplit[1]
        trackArtists.push({
            artistId: artistDataSplit[0],
            share: artistDataSplit[1]
          })
    });

    if (splits !== 100) {
        toast.error("Artist splits don't add up to 100!")
        return;
    }

    let name = document.getElementById("TrackName").value

    if (!name || name.length < 1) {
        toast.error("Name can't be empty!")
        return;
    }

    let date = document.getElementById("ReleaseDate").value;
    date = dayjs(date, "DD.MM.YYYY").format("YYYY-MM-DD")

    let formData = new FormData();
    formData.append("trackArtistInputs", JSON.stringify(trackArtists));
    formData.append("name", name);
    formData.append("releaseDate", date);
    formData.append("split", values.split);
    formData.append("releasetagId", values.releaseTagId);
    submit(formData, { method: "post" });
}
