import { postWithAuth, putWithAuth } from "../Helpers/authHelper";
import { toast } from 'react-toastify';
import { API_URL } from "../config";

export async function updateArtist({ request, params }) {

  let data = await request.formData()

  let result = await putWithAuth(API_URL + "artist/" + data.get("Id"), getData(data))

  if (result.success) {
    toast.success("Artist updated successfully.")
  } else { 
    toast.error("Failed to update artist.")
  }

  return null
}

export async function createArtist({ request, params }) {

  let data = await request.formData()

  let result = await postWithAuth(API_URL + "artist", getData(data))

  if (result.success) {
    toast.success("Artist created successfully.")
  } else { 
    toast.error("Failed to create artist.")
  }

  return null
}

const getData = (data) => {
  return {
    Name: data.get("Name"),
    FirstName: data.get("FirstName"),
    LastName: data.get("LastName"),
    Country: data.get("Country"),
    City: data.get("City"),
    Paymentinfo: data.get("Paymentinfo"),
    ChannelId: data.get("ChannelId")
  }
}
