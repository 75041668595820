import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from "./List";

export default function AccordionList(props) {
    return (
        <>
            <Accordion className={props.className} sx={props.sx} defaultExpanded={props.defaultExpanded}>

                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{props.header}</Typography>
                    {
                        props.secondaryHeader
                            ? <Typography sx={{ color: 'text.secondary', marginLeft: "10px" }}>{props.secondaryHeader}</Typography>
                            : ""
                    }
                </AccordionSummary>

                <AccordionDetails>
                    <List
                        onClick={props.onClick}
                        data={props.data}
                        getPrimaryText={props.getPrimaryText}
                        getSecondaryText={props.getSecondaryText}
                        getAvatarSrc={props.getAvatarSrc}
                        getIconAvatarSrc={props.getIconAvatarSrc}
                    />
                </AccordionDetails>

            </Accordion>
        </>
    );
}
