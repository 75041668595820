import { API_URL } from "../config";
import { fetchWithAuth } from "../Helpers/authHelper";

export async function loadReview({ params }) {
  return (await fetchWithAuth(API_URL + "review/" + params.Id)).data
}

export async function loadAllReviews() {
  return (await fetchWithAuth(API_URL + "review")).data
}
