import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { getAvatar, getIcon } from './List';
import { ListItemButton } from '@mui/material';


export default function ListButton(props) {

  return (
    <List component={props.component} sx={{ width: '100%', }}>

      {props.data.map((item, i) => {

        let isNextDivider = false


        if (props.data.length > i+1) {
          isNextDivider = props.data[i+1].type === "divider"
        }

        return getComponent(props, item, i, isNextDivider)
      })
      }
    </List>
  );
}

const getComponent = (props, item, i, isNextDivider) => {
  if (item.type === "button") {
    return (
      <React.Fragment key={"List-" + i}>
        <ListItemButton
          alignItems="flex-start"
          onClick={() => props.onClick(item)}
          selected={props.selectedIndex === i}
        >
          {
            getAvatar(item, props.getAvatarSrc)
          }

          {
            getIcon(item, props.getIcon)
          }

          <ListItemText
            sx={{ wordWrap: "break-word" }}
            primary={!props.getPrimaryText ? "" : props.getPrimaryText(item)}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline', color: "" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {!props.getSecondaryText ? "" : props.getSecondaryText(item)}
                </Typography>
              </React.Fragment>
            }
          />

        </ListItemButton>
        {
          isNextDivider
            ? ""
            : <Divider component="li" />
        }
      </React.Fragment>)
  }

  if (item.type === "divider") {
    return <Divider>{item.text}</Divider>
  }

}