import { deleteWithAuth, postWithAuth } from "../Helpers/authHelper";
import { toast } from 'react-toastify';
import { API_URL } from "../config";

export async function updateReleaseTaskForTrack({ request, params }) {

  let data = await request.formData()

  let trackId = data.get("TrackId")
  let releaseTaskId = data.get("ReleaseTaskId")
  let action = data.get("Action")

  console.log(trackId, releaseTaskId, action);

  if (!trackId || trackId < 0) {
    toast.error("Track ID can not be empty. (Should really not happen, lmk if it does)")
    return null
  }

  if (!releaseTaskId || releaseTaskId < 0) {
    toast.error("TaskId ID can not be empty. (Should really not happen, lmk if it does)")
    return null
  }

  let result = undefined;

  if (action === "Post") {
    result = await postWithAuth(API_URL + "track/" + trackId + "/task/" + releaseTaskId, {})
  }

  if (action === "Delete") {
    result = await deleteWithAuth(API_URL + "track/" + trackId + "/task/" + releaseTaskId, {})
  }

  if (result.success) {
    toast.success("Releasetask updated successfully.")
  } else {
    toast.error("Error while updating task.")
  }

  return result
}

export async function createTrack({ request, params }) {

  let data = await request.formData()

  let trackArtistInputs = JSON.parse(data.get("trackArtistInputs"))
  let name = data.get("name")
  let releaseDate = data.get("releaseDate")
  let split = data.get("split")
  let releasetagId = data.get("releasetagId")

  let result = await postWithAuth(API_URL + "track", 
  {
    name,
    releaseDate,
    split,
    releasetagId,
    trackArtistInputs
  })

  if (result.success) {
    toast.success("Track created successfully.")
  } else {
    toast.error("Error while createing track.")
  }

  return result
}

