import { putWithAuth } from "../Helpers/authHelper";
import { toast } from 'react-toastify';
import { API_URL } from "../config";

export async function updateReleaseTask({ request, params }) {

  let data = await request.formData()

  let text = data.get("Text")

  if (text.length === 0) {
    toast.error("Text can not be empty.")
    return null
  }

  if (text.length > 255) {
    toast.error("Text can not be longer than 255 characters.")
    return null
  }

  let result = await putWithAuth(API_URL + "task/" + params.Id, {
      UserId: data.get("UserId"),
      Text: data.get("Text"),
      ReminderOffsetInDays: data.get("ReminderOffsetInDays"),
    })

    if (result.success) {
      toast.success("Releasetask updated successfully.")
    }

    return result
}

