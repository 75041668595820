import { API_URL } from "../config";
import { fetchWithAuth } from "../Helpers/authHelper";

export async function loadArtistTracks({ params }) {
  return (await fetchWithAuth(API_URL + "artists/" + params.ArtistName + "/tracks")).data
}

export async function loadTrack({ params }) {
  return [
    (await fetchWithAuth(API_URL + "tracks/" + params.TrackName)).data,
    (await fetchWithAuth(API_URL + "user")).data,
    (await fetchWithAuth(API_URL + "current-user")).data
  ]
}

export async function loadReleaseTags({ params }) {
  return (await fetchWithAuth(API_URL + "releasetags")).data
}