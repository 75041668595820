import React from 'react';
import { Autocomplete, Grid, Slider, Stack, TextField, Typography } from "@mui/material";

export default function ArtistSplit(props) {

    const [values, setValues] = React.useState({split: 100, artistName: props.defaultValue});

    const handleChange = (event, newValue) => {
        setValues({ ...values, split: newValue });
        props.handleChange(values.artistName, newValue)

    };

    const handleArtistChange = (event, newValue) => {
        let oldName = values.artistName
        setValues({ ...values, artistName: newValue });
        props.handleChange(newValue, values.split, oldName?.Id ?? "")
    };

    if (!props.data || !props.handleChange) {
        return ""
    }

    return (
        <React.Fragment key={"createArtistSplit" + props.index}>
            <Grid spacing={1} container >
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={props.data}
                        renderInput={(params) => <TextField fullWidth {...params} label={props.index + ". Artist"} />}
                        value={values.artistName}
                        getOptionLabel={(option) => option.Name}
                        disabled={props.disabled}
                        onChange={handleArtistChange}
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.Name === value.Name}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography id="input-slider" gutterBottom>
                        {props.index}. artist split:
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Typography>0%</Typography>
                        <Slider
                            aria-label="Volume"
                            value={values.split}
                            step={5}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="on"
                            onChange={handleChange}
                            disabled={!props.defaultValue}
                        />
                        <Typography>100%</Typography>
                    </Stack>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}