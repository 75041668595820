import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { Link, Outlet, useLoaderData, useNavigate, useParams, useSubmit } from 'react-router-dom';
import { withAuthenticationRequired } from 'react-oidc-context';
import ListButton from './ListButton';
import ArtistSelectPopper from './ArtistSelectPopper';
import { updateSelectedArtistDirect } from '../Actions/dashboard';
import LibraryMusic from '@mui/icons-material/LibraryMusic';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            Pintraw
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function bestMatchIndex(objects, filterString) {
    // Filter objects whose names start with the filter string and keep their indexes
    const matchingIndexes = objects
        .map((obj, index) => ({ obj, index }))
        .filter(({ obj }) => obj.path?.startsWith(filterString));

    if (matchingIndexes.length === 0) {
        return -1; // Return -1 if no match is found
    }

    // Determine the best match index based on the shortest name
    const bestMatch = matchingIndexes.reduce((best, current) => {
        return current.obj.path.length < best.obj.path.length ? current : best;
    }, matchingIndexes[0]);

    return bestMatch.index;
}

function Dashboard() {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const navigate = useNavigate();

    const { user, artists } = useLoaderData()

    const [artistSelectValue, setArtistSelectValue] = React.useState(user.SelectedArtistName);

    const submit = useSubmit();

    const params = useParams();

    if (params.ArtistName && params.ArtistName.toLowerCase() !== artistSelectValue.toLowerCase()) {

        let artist = artists.find(artist => artist.Name.toLowerCase() === params.ArtistName.toLowerCase())
        if (artist) {
            let formData = new FormData();
            formData.append("SelectedArtistId", artist.Id);
            updateSelectedArtistDirect(formData)
            setArtistSelectValue(artist.Name);
        }
    }

    let drawerOptions = [
        { text: "Dashboard", icon: <DashboardIcon />, path: "/", type: "button" },
        { text: "Label", type: "divider" },
        { text: "Artists", icon: <PeopleIcon />, path: "/artists", type: "button" },
        { text: "Reviews", icon: <RateReviewIcon />, path: "/reviews", type: "button" },
        { text: "Release Tasks", icon: <TaskAltIcon />, path: "/tasks", type: "button" },
        { text: "Artist", type: "divider" },
        { text: "Artist information", icon: <PersonIcon />, path: "/artists/" + artistSelectValue, type: "button" },
        { text: "Tracks", icon: <LibraryMusic />, path: "/artists/" + artistSelectValue + "/tracks", type: "button" },

    ]

    let currentRouteIndex = bestMatchIndex(drawerOptions, window.location.pathname)

    return (
        <Box sx={{ display: 'flex' }}>

            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                        width: "100%"
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        <Link style={{ textDecoration: "inherit", color: "inherit" }}>TLWN Dashboard</Link>
                    </Typography>

                    <ArtistSelectPopper
                        options={getArtistOptions(artists)}
                        defaultValue={user.SelectedArtistName}
                        value={artistSelectValue}
                        onChange={(event, newValue) => {
                            setArtistSelectValue(newValue.label);
                            let formData = new FormData();
                            formData.append("SelectedArtistId", newValue.Id);
                            submit(formData, { method: "post" });
                            navigate("/artists/" + newValue.Name)
                        }}
                        isOptionEqualToValue={(a, b) => {
                            return a.label === b
                        }} />
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <ListButton
                    component="nav"
                    data={drawerOptions}
                    onClick={(option) => navigate(option.path)}
                    getPrimaryText={(option) => option.text}
                    getIcon={(option) => option.icon}
                    selectedIndex={currentRouteIndex}
                />
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Outlet />
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}

const getArtistOptions = (artists) => {
    return artists.map((a) => {
        return {
            ...a,
            label: a.Name
        }
    })
}


export default withAuthenticationRequired(Dashboard, { signinRedirectArgs: { url_state: window.location.pathname + window.location.search } })